import { Mobile_View_Width, Tablet_View_Width, Laptop_View_Width } from "../constants/DeviceInfo";

export const checkMobileView = () => {
  return window.innerWidth < Mobile_View_Width;
};
export const checkTabletView = () => {
  return window.innerWidth < Tablet_View_Width;
};
export const checkLaptopView = () => {
  return window.innerWidth < Laptop_View_Width;
};
