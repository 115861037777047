import React, { Suspense, lazy, useContext } from "react";
import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";

import { liveQuizContext } from "./context/context";
import useScrollToTop from "./hooks/useScrollToTop";
import {
  DashboardRoute,
  AboutUsRoute,
  BaseRoute,
  HelpAndFAQRoute,
  SupportAndFeedbackRoute,
  TermsAndConditionsRoute,
  PoliciesRoute,
  NoMatchRoute,
  TermsAndConditionsPageRoute,
  PoliciesPageRoute,
  DailyQuizRoute,
  LeaderBoardRoute,
  LiveQuizRoute,
  LoginPageRoute,
  EmptyPageRoute,
  DemoAppRoute,
} from "./constants/RouteConstants";
import AuthRoute from "./components/AuthRoute";
import Loader2 from "./reusable/Loader2";

// Lazy load pages
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Auth = lazy(() => import("./pages/Auth"));
const About = lazy(() => import("./pages/About"));
const HelpFAQ = lazy(() => import("./pages/HelpFAQ"));
const SupportFeedback = lazy(() => import("./pages/SupportFeedback"));
const TermsConditions = lazy(() => import("./pages/TermsConditions"));
const TermsConditionsPage = lazy(() => import("./pages/TermsConditions"));
const LeaderBoardPage = lazy(() => import("./pages/LeaderBoard"));
const OurPolicies = lazy(() => import("./pages/OurPolicies"));
const OurPoliciesPage = lazy(() => import("./pages/OurPolicies"));
const Home = lazy(() => import("./pages/Home"));
const StaticQuiz = lazy(() => import("./pages/StaticQuiz"));
const LiveQuiz = lazy(() => import("./pages/LiveQuiz"));
const EmptyPage = lazy(() => import("./pages/EmptyPage"));
const DemoQuiz = lazy(() => import("./pages/DemoQuiz"));

function App() {
  const { isLiveQuizLive } = useContext(liveQuizContext);

  const pageRoutes = [
    {
      path: LoginPageRoute,
      element: <Auth />,
      isProtected: false,
      isAvailable: true,
    },
    {
      path: TermsAndConditionsPageRoute,
      element: <TermsConditionsPage />,
      isProtected: false,
      isAvailable: true,
    },
    {
      path: PoliciesPageRoute,
      element: <OurPoliciesPage />,
      isProtected: false,
      isAvailable: true,
    },
    {
      path: LeaderBoardRoute,
      element: <LeaderBoardPage />,
      isProtected: true,
      isAvailable: true,
    },
    {
      path: LiveQuizRoute,
      element: <LiveQuiz />,
      isProtected: true,
      isAvailable: isLiveQuizLive,
    },
    {
      path: EmptyPageRoute,
      element: <EmptyPage />,
      isProtected: false,
      isAvailable: true,
    },
  ];

  const dashboardOutletRoutes = [
    { path: DashboardRoute, element: <Dashboard /> },
    { path: AboutUsRoute, element: <About /> },
    { path: HelpAndFAQRoute, element: <HelpFAQ /> },
    { path: SupportAndFeedbackRoute, element: <SupportFeedback /> },
    { path: TermsAndConditionsRoute, element: <TermsConditions /> },
    { path: PoliciesRoute, element: <OurPolicies /> },
    { path: DailyQuizRoute, element: <StaticQuiz /> },
  ];

  useScrollToTop();

  return (
    <div className="h-svh">
      <Routes>
        <Route element={<AuthRoute />}>
          <Route path={BaseRoute} element={<Home />}>
            {dashboardOutletRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <Suspense fallback={<SuspensePage />}>
                    {route.element}
                  </Suspense>
                }
              />
            ))}
            <Route
              path={NoMatchRoute}
              element={<Navigate to={DashboardRoute} replace />}
            />
          </Route>
        </Route>
        <Route path={DemoAppRoute} element={<DemoQuiz />} />

        {pageRoutes.map(
          (route) =>
            route.isAvailable &&
            (route.isProtected ? (
              <Route key={route.path} element={<AuthRoute />}>
                <Route
                  key={route.path}
                  path={route.path}
                  element={
                    <Suspense fallback={<SuspensePage />}>
                      {route.element}
                    </Suspense>
                  }
                />
              </Route>
            ) : (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <Suspense fallback={<SuspensePage bgColor="linear-gradient(90deg, #421ae8 0%, #251958 100%)" />}>
                    {route.element}
                  </Suspense>
                }
              />
            ))
        )}

        <Route
          path={NoMatchRoute}
          element={<Navigate to={DashboardRoute} replace />}
        />
      </Routes>
    </div>
  );
}

export default App;

const SuspensePage = ({ bgColor = "#594991" }) => {
  return (
    <div
      style={{
        background: bgColor,
      }}
      className=" h-full w-full grid place-items-center"
    >
      <Loader2 />
    </div>
  );
};
